import React, { useEffect, useState } from 'react'
import Pdf from "./assets/vprasalnik-za-sprejem.pdf";

export default function SprejemVDom() {

    useEffect(() => {
        
    }, [])

    const onOpenPdf = () =>  {
        window.open(Pdf);
    }

    return (
        <>
            <div className="uk-container">
                <p className="sprejem_v_dom_header">
                Za sprejem v Dom morate oddati:
                </p>

                <div className="uk-child-width-1-5" uk-grid="true">
                    <div className="uk-width-3-5@m uk-width-5-5">
                        <p className="sprejem_v_dom_text">I. Motivacijsko pismo – osebno prošnjo, kjer razložite, zakaj želite postati del našega Doma in kje vse ter kako ste aktivni (v župniji in drugih (prostovoljnih) organizacijah).</p>

                        <p className="sprejem_v_dom_text">II. Priporočilno pismo domačega župnika.</p>

                        <p className="sprejem_v_dom_text">III. Potrdilo o dohodkih in številu družinskih članov – fotokopija (odločba CSD o otroškem dodatku, ali podatki o osebnih dohodkih, dohodninske odločbe, pokojninske odločbe ...).</p>

                        <p className="sprejem_v_dom_text">IV. Fotokopijo spričevala zadnjega opravljenega letnika ali izpis ocen preteklega študijskega leta s povprečno oceno.</p>

                        <p className="sprejem_v_dom_text">V. Izpolnjen vprašalnik za sprejem.</p>

                        <p className="sprejem_v_dom_text">VI. Priporočila še drugih organizacij ali ljudi, ki so veseli vašega angažmaja (poleg župnikovega, ki je obvezno).</p>

                        <p className="sprejem_v_dom_text">Kot referenca velja tudi oseben obisk Doma, kjer si skupaj ogledamo prostore in pogovorimo o življenju.</p>
                    </div>


                    <div className="uk-width-2-5@m uk-width-5-5">

                        <div className='orange_box_zgoraj'>
                            <p className="inside_text_right_boxes">
                                Ministrstvo za visoko šolstvo, znanost in inovacije za naš Dom za študijsko leto 2023/24 dodeljuje 40 subvencioniranih mest.
                            </p>
                        </div>
                        
                        <div className="orange_box_sredina">
                            <p className="inside_text_middel_boxes">
                                Cena bivanja za študijsko leto 2023/24 bo znašala 150 EUR brez subvencije oziroma 118 EUR s subvencijo.
                            </p>
                        </div>

                        <div className="orange_box_spodaj">
                            <p className="inside_text_down_boxes">
                                <a className="inside_href_text_down_boxes" 
                                    onClick={() => onOpenPdf()}
                                    target="_blank">
                                        VPRAŠALNIK ZA SPREJEM .pdf
                                </a>
                            </p>
                        </div>

                    </div>
                </div>


                <p className="sprejem_v_dom_text_down">
                    Prosimo, da vašo vlogo z omenjenimi dokumenti v fizični obliki pošljete na naš naslov čim prej, saj vsak mesec prinese nekaj bonitet. Prošnje za naslednje študijsko leto sprejemamo do konca junija predhodnega študijskega leta.
                    <br />
                    Za vse nove študente, ki bodo v Študentskem domu Vincencij pričeli prebivati v študijskem letu 2025/26, je obvezen obisk Vincencijanskega tečaja.
                </p>
                
            </div>
        </>
    )
}