import React, { useEffect, useState } from 'react';
import { getDatabase, onValue, get, ref as firebaseRef } from "firebase/database";
import { getDownloadURL, getStorage, ref as storageRef } from "firebase/storage";
import { Link } from 'react-router-dom';

const N = 10

export default function Skupine() {
    const [firstNDogodki, setFirstNDogodki] = useState([]);
    const [otherDogodki, setOtherDogodki] = useState([]);
    const [tmpDogodki, setTmpDogodki] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state

    useEffect(() => {
        const db = getDatabase();
        const storage = getStorage();

        const dogodkiRef = firebaseRef(db, '/dogodki');
        let tmpArrayDogodkov = [];

        const tmpCachedDogodki = getObject("tmpDogodki");
        tmpCachedDogodki && setTmpDogodki(tmpCachedDogodki);

        onValue(dogodkiRef, (snapshot) => {

            let numOfEvents = 0;
            snapshot.forEach(() => {
                numOfEvents += 1;
            });

            tmpArrayDogodkov = [];

            snapshot.forEach((childSnapshot) => {
                const dogodek = {
                    id: childSnapshot.key,
                    naslov: childSnapshot.child('naslov').val(),
                    datum: childSnapshot.child('datum').val(),
                    kratekOpis: childSnapshot.child('kratekOpis').val(),
                    naslovnaSlika: childSnapshot.child('naslovnaSlika').val(),
                    naslovnaSlikaUrl: tmpCachedDogodki?.length > tmpArrayDogodkov.length ? tmpCachedDogodki[tmpArrayDogodkov.length].naslovnaSlikaUrl : ""
                };

                tmpArrayDogodkov.push(dogodek);

                if (numOfEvents === tmpArrayDogodkov.length) {
                    tmpArrayDogodkov = tmpArrayDogodkov.reverse();

                    tmpCachedDogodki?.length === 0 && setTmpDogodki(tmpArrayDogodkov);

                    let updateValues = JSON.stringify(tmpCachedDogodki) !== JSON.stringify(tmpArrayDogodkov);

                    if (updateValues) {
                        fillFirstNUrls(storage, 0, tmpArrayDogodkov.length, tmpArrayDogodkov);
                    } else {
                        setLoading(false); // Stop loading if no updates are needed
                    }
                }
            });
        });
    }, []);

    const fillFirstNUrls = (storage, index, maxIndex, tmpArrayDogodkov) => {
        if (index === N || index === maxIndex) {
            setFirstNDogodki(tmpArrayDogodkov.filter((dogodek, idx) => idx < N));
            setLoading(false); // Stop loading after first N URLs are filled
            return;
        }

        getDownloadURL(storageRef(storage, tmpArrayDogodkov[index].naslovnaSlika))
            .then((url) => {
                tmpArrayDogodkov[index].naslovnaSlikaUrl = url;
                fillFirstNUrls(storage, index + 1, maxIndex, tmpArrayDogodkov);
            })
            .catch((error) => {
                setLoading(false); // Stop loading on error
            });
    };

    useEffect(() => {
        const storage = getStorage();

        const newTmpArrayDogodkov = tmpDogodki.filter((dogodek, idx) => idx >= N);
        if (firstNDogodki.length > 0) {
            setLoading(true); // Restart loading for other URLs
            fillOtherUrls(storage, 0, newTmpArrayDogodkov.length, newTmpArrayDogodkov);
        }
    }, [firstNDogodki]);

    const fillOtherUrls = (storage, index, maxIndex, newTmpArrayDogodkov) => {
        if (index === maxIndex) {
            persistObject("tmpDogodki", firstNDogodki.concat(newTmpArrayDogodkov));
            setOtherDogodki(newTmpArrayDogodkov);
            setLoading(false); // Stop loading after all URLs are filled
            return;
        }

        getDownloadURL(storageRef(storage, newTmpArrayDogodkov[index].naslovnaSlika))
            .then((url) => {
                newTmpArrayDogodkov[index].naslovnaSlikaUrl = url;
                fillOtherUrls(storage, index + 1, maxIndex, newTmpArrayDogodkov);
            })
            .catch((error) => {
                setLoading(false); // Stop loading on error
            });
    };

    const persistObject = (name, object) => {
        localStorage.setItem(name, JSON.stringify(object));
    };

    const getObject = (name) => {
        return JSON.parse(localStorage.getItem(name));
    };

    useEffect(() => {
        const prevYOffset = getObject("yPositionDogodki");
        window.scrollTo({ top: prevYOffset, behavior: "instant" });
    }, [tmpDogodki]);

    const Dogodek = (dogodek) => {
        return (
            <div className="uk-width-1-2@m uk-width-1-3@xl uk-width-1-1">
                <Link onClick={() => handleScrollPosition()} className="nav-link-header" to={"/dogodek/" + dogodek.id}>
                    <div key={dogodek.key} className="dogodek_group_element">
                        <div className='orange_box_novica_group'>
                            <p className="inside_title_dogodek_group">
                                {dogodek.naslov.length > 25 ? dogodek.naslov.slice(0, 25) + "..." : dogodek.naslov}
                            </p>
                            <p className="datum_dogodek_group">
                                {dogodek.datum}
                            </p>
                            <p className="inside_text_dogodek_group">
                                {dogodek.kratekOpis.length > 90 ? dogodek.kratekOpis.slice(0, 90) + "..." : dogodek.kratekOpis}
                            </p>
                        </div>

                        <div className="slika_group_novice">
                            {dogodek.naslovnaSlikaUrl && <img className="slika_group_novice_tag" src={dogodek.naslovnaSlikaUrl} alt={dogodek.naslov} />}
                        </div>
                    </div>
                </Link>
            </div>
        );
    };

    const handleScrollPosition = () => {
        persistObject("yPositionDogodki", window.pageYOffset);
    };

    return (
        <>
            <div className="uk-container">
                {loading && (
                    <div className="loader">
                        <div className="spinner"></div>
                        {/* <p>Loading...</p> */}
                    </div>
                )}

                <div className="uk-child-width-1-3" uk-grid="true">
                    {firstNDogodki.length === 0 && otherDogodki.length === 0 && tmpDogodki.map(dogodek => {
                        return Dogodek(dogodek);
                    })}

                    {firstNDogodki.length > 0 && otherDogodki.length === 0 && tmpDogodki.filter((dogodek, idx) => idx >= N).map(dogodek => {
                        return Dogodek(dogodek);
                    })}

                    {firstNDogodki.map(dogodek => {
                        return Dogodek(dogodek);
                    })}

                    {otherDogodki.map(dogodek => {
                        return Dogodek(dogodek);
                    })}
                </div>
            </div>
        </>
    );
}

